import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

// Featured News Swiper
const entryCardSwiper = new Swiper('.entry-card-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1.1,
    spaceBetween: 20,
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    breakpoints: {

        768: {
            slidesPerView: 1.5,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 2.2,
            spaceBetween: 25,
        },
        1300: {
            slidesPerView: 2.2,
            spaceBetween: 25,
        }
    }
    
  });

// Entry Swiper
const entrySwiper = new Swiper('.entry-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {

        768: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 25,
        },
        1300: {
            slidesPerView: 3.2,
            spaceBetween: 25,
        }
    }
    
  });

// Logo Swiper
const logoSwiper = new Swiper('.logo-swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 3,
  spaceBetween: 20,
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
  breakpoints: {

    768: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    1024: {
        slidesPerView: 4,
        spaceBetween: 25,
    },
    1300: {
        slidesPerView: 5,
        spaceBetween: 25,
    },
    1600: {
      slidesPerView: 6,
      spaceBetween: 25,
    }
}
  
  
});

// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
  navEx.addEventListener("click", function() {
      if(mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.remove("mobile-nav-active")
        
      }   
  })
}
if(navBtn){
navBtn.addEventListener("click", function() {
    if(!mobileNav.classList.contains("mobile-nav-active")) {
      mobileNav.classList.add("mobile-nav-active")
      
    }
})
}


// contact form modal 
const contactModalBtn = document.getElementById("contact-modal-btn")
const contactModalBtnClose = document.getElementById("contact-modal-btn-close")
const contactModal = document.getElementById("contact-modal")

if(contactModalBtn) {
  contactModalBtn.addEventListener("click", (e) => {
    e.preventDefault()
    
    contactModal.classList.add("translate-x-0")
    contactModal.classList.remove("translate-x-full")
  })
}

if(contactModalBtnClose) {
  contactModalBtnClose.addEventListener("click", () => {
 
    contactModal.classList.remove("translate-x-0")
    contactModal.classList.add("translate-x-full")
  })
}

// contact form modal dragging

var dragItem = contactModal 
var container = document.querySelector("body");

var active = false;
var currentX;
var currentY;
var initialX;
var initialY;
var xOffset = 0;
var yOffset = 0;

container.addEventListener("touchstart", dragStart, false);
container.addEventListener("touchend", dragEnd, false);
container.addEventListener("touchmove", drag, false);

container.addEventListener("mousedown", dragStart, false);
container.addEventListener("mouseup", dragEnd, false);
container.addEventListener("mousemove", drag, false);

function dragStart(e) {
  if (e.type === "touchstart") {
    initialX = e.touches[0].clientX - xOffset;
    initialY = e.touches[0].clientY - yOffset;
  } else {
    initialX = e.clientX - xOffset;
    initialY = e.clientY - yOffset;
  }

  if (e.target === dragItem) {
    active = true;
  }
}

function dragEnd(e) {
  initialX = currentX;
  initialY = currentY;

  active = false;
}

function drag(e) {
  if (active) {
  
    e.preventDefault();
  
    if (e.type === "touchmove") {
      currentX = e.touches[0].clientX - initialX;
      currentY = e.touches[0].clientY - initialY;
    } else {
      currentX = e.clientX - initialX;
      currentY = e.clientY - initialY;
    }

    xOffset = currentX;
    yOffset = currentY;

    setTranslate(currentX, currentY, dragItem);
  }
}

function setTranslate(xPos, yPos, el) {
  el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
}

// pop up modal 
const popModalBtnClose = document.getElementById("pop-up-close")
const popModal = document.getElementById("pop-up-modal")
let disableCookie = sessionStorage.getItem("mbx-modal-disable");




if(disableCookie){
  setTimeout(() => {
    popModal.classList.add("hidden")
  }, 500)
} else {
  if(popModalBtnClose) {
    popModalBtnClose.addEventListener("click", () => {
      popModal.classList.remove("translate-x-0")
      popModal.classList.add("translate-over-right")
      setTimeout(() => {
        popModal.classList.add("hidden")
      }, 500)
      sessionStorage.setItem("mbx-modal-disable", "true");
    })
  }
}



// custom mouse movement
const cursor = document.querySelector('.cursor');
const cursorinner = document.querySelector('.cursor2');
const cursorinnerText = document.querySelector('.cursor2 > span');
const achorTags = document.querySelectorAll("a")
const buttonTags = document.querySelectorAll("button")
const slides = document.querySelectorAll(".swiper-slide")
const whiteSections = document.querySelectorAll("section.bg-white")




if(cursor && cursorinner ) {
  
      gsap.to(cursor, {opacity:0})
      gsap.to(cursorinner, {opacity:0})
      let cursorTimout 
    document.addEventListener('pointermove', function(e){
     
      gsap.to(cursor, {opacity:.5})
      gsap.to(cursorinner, {opacity:1})
      clearTimeout(cursorTimout);

        var x = e.clientX;
        var y = e.clientY;
        cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
        cursorinner.style.left = x + 'px';
        cursorinner.style.top = y + 'px';

        cursorTimout = setTimeout(
          () => {
            gsap.to(cursor, {opacity:0})
            gsap.to(cursorinner, {opacity:0})
          },
          1000
        );
    });
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      slide.addEventListener("mouseover", (e) => {
        if(e.target.href){
          cursorinner.classList.remove("cursor2-hover-large")
          cursorinnerText.innerHTML = "view"
        } else {
          cursorinner.classList.add("cursor2-hover-large")
          cursorinnerText.innerHTML = "drag"
        }
          
      })
      slide.addEventListener("mouseout", () => {
          cursorinner.classList.remove("cursor2-hover-large")
          cursorinnerText.innerHTML = "view"
      })
  }
  

    for (let i = 0; i < achorTags.length; i++) {
        const anchor = achorTags[i];
        if(anchor.classList.contains("hover-large")) {
          anchor.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover-large")
        })
        anchor.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover-large")
        })
        } else {
          anchor.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        anchor.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
        }
       
    }
    for (let i = 0; i < buttonTags.length; i++) {
        const button = buttonTags[i];
        button.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        button.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
    }
    
}

// hover effect on selected entries
const selectedEntries = document.querySelectorAll(".selected-entry")
const selectedEntryHeadline = document.querySelectorAll(".selected-entry h4")

for (let i = 0; i < selectedEntries.length; i++) {
  const el = selectedEntries[i];
  const elHeadline = selectedEntryHeadline[i];

  if(cursor && cursorinner ) {
  
    el.addEventListener("mouseover", () => {
      gsap.to(elHeadline, {y:-5, duration:.3})
      cursorinner.classList.add("cursor2-hover-large")
      cursorinnerText.innerHTML = "view"

    })
    el.addEventListener("mouseout", () => {
      gsap.to(elHeadline, {y:0, duration:.3})
      cursorinner.classList.remove("cursor2-hover-large")

    })
  }
}


for (let i = 0; i < whiteSections.length; i++) {
  const element = whiteSections[i];

  element.addEventListener("mouseover", () => {
    cursorinner.style.backgroundColor = "#000000"
    cursorinner.style.borderColor= "#000000"
    cursorinnerText.style.color = "#ffffff"
  })
  element.addEventListener("mouseout", () => {
    cursorinner.style.backgroundColor = "#ffffff"
    cursorinner.style.borderColor= "#ffffff"
    cursorinnerText.style.color = "#000000"
  })
  
}


// scrollback functionality

function debounce(func, timeout = 10){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

const header = document.getElementById("header")
const currentBanner = document.getElementById("banner")

var current = window.scrollY
var paddedUp = 0
var paddedDown = 0
if(innerPage){
  header.classList.add("bg-black")
}
function saveInput(){
  if(innerPage){
      
      if(window.scrollY < current){
        if(window.scrollY < paddedUp) {
        header.classList.remove("scroll-down")
        header.classList.add("scroll-up")
        }
        paddedDown = window.scrollY + 100
        header.classList.add("bg-black")
      } else {
        if(window.scrollY > paddedDown) {
          header.classList.remove("scroll-up")
          header.classList.add("scroll-down")
          paddedUp = window.scrollY - 100
        }
       
      }
      current = window.scrollY
      header.classList.add("bg-black")
  }else{
    if(window.scrollY > 200) {
      if(window.scrollY < current){
        if(window.scrollY < paddedUp) {
        header.classList.remove("scroll-down")
        header.classList.add("scroll-up")
        }
        paddedDown = window.scrollY + 100
        
      } else {
        if(window.scrollY > paddedDown) {
          header.classList.remove("scroll-up")
          header.classList.add("scroll-down")
          setTimeout(() => {
            header.classList.add("bg-black")
          }, 300);
          paddedUp = window.scrollY - 100
        }
       
      }
      current = window.scrollY
    } else {
      header.classList.remove("scroll-down")
      header.classList.add("scroll-up")
      header.classList.remove("bg-black")
    }
  }
    
 
}

function saveInputWhite(){
  
    if(window.scrollY > 200) {
      if(window.scrollY < current){
        if(window.scrollY < paddedUp) {
        header.classList.remove("scroll-down")
        header.classList.add("scroll-up")
        }
        paddedDown = window.scrollY + 100
      } else {
        if(window.scrollY > paddedDown) {
          header.classList.remove("scroll-up")
          header.classList.add("scroll-down")
          setTimeout(() => {
            header.classList.add("bg-white")
          }, 300);
          paddedUp = window.scrollY - 100
        }
      }
      current = window.scrollY
    } else {
      header.classList.remove("scroll-down")
      header.classList.add("scroll-up")
      header.classList.remove("bg-white")
    }
 
}

if(currentBanner && currentBanner.classList.contains("bg-white") ){
    const logoScroll = debounce(() => saveInputWhite());
    window.addEventListener("scroll", logoScroll);
} else {
    const logoScroll = debounce(() => saveInput());
    window.addEventListener("scroll", logoScroll);

}

// Home banner animation
const banner = document.getElementById("home-banner")
const textOne = document.getElementById("home-banner-text-1")
const textTwo = document.getElementById("home-banner-text-2")

if(banner){
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: banner,
            start: "top top+=100",
            end: "bottom top",
            scrub: true
        }
    })

    tl
    .to(textOne, {x:100, ease: "sine"})
    .to(textTwo, {x:-100, ease: "sine"}, "<")

}

// btn animations
const arrowBtns = document.querySelectorAll(".btn-arrow")
const arrows = document.querySelectorAll(".btn-arrow svg")
for (let i = 0; i < arrowBtns.length; i++) {
    const element = arrowBtns[i];
    element.addEventListener("mouseover", () => {
      arrows[i].classList.add("btn-hover")
    })
    element.addEventListener("mouseout", () => {
      arrows[i].classList.remove("btn-hover")
    })
}

// Work Filter Scrolltrigger
let filters = document.getElementById("filters-wrapper")
let filterResults = document.getElementById("filter-results")
if(filters) {
const filterEnter = () => {
 gsap.to(filters, {bottom: "5rem"})
}
const filterExit = () => {
  gsap.to(filters, {bottom: "-5rem"})
 }


ScrollTrigger.create({
    trigger: filterResults,
    start: "top bottom",
    end: "bottom bottom",
    onEnter: filterEnter,
    onLeave: filterExit,
    onEnterBack: filterEnter,
    onLeaveBack: filterExit
  })

}

document.addEventListener('htmx:afterRequest', function(evt) {




  // Work Filter Scrolltrigger
  let filters = document.getElementById("filters-wrapper")
  let filterResults = document.getElementById("filter-results")
  if(filters) {
    gsap.to(filters, {bottom: "5rem"})
  const filterEnter = () => {
   gsap.to(filters, {bottom: "5rem"})
  }
  const filterExit = () => {
    gsap.to(filters, {bottom: "-5rem"})
   }
  
  
  ScrollTrigger.create({
      trigger: filterResults,
      start: "top bottom",
      end: "bottom bottom",
      onEnter: filterEnter,
      onLeave: filterExit,
      onEnterBack: filterEnter,
      onLeaveBack: filterExit
    })
  
  }

  var reveals = gsap.utils.toArray(".gs_reveal");
  for(var i = 0; i < reveals.length; i++) {
  (function () {
  var elem = reveals[i];
  //   hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      
      once: true,
      onEnter: function() { animateFrom(elem) }, 
      
    });
  })();
  } 

 
  
})

document.addEventListener('htmx:beforeRequest', function(event) {
  let filterResultsWrapper = document.getElementById("filter-results-wrapper")
  var elDistanceToTop = window.scrollY + filterResultsWrapper.getBoundingClientRect().top
  window.scrollTo(0, elDistanceToTop)
});



// animated line 
const aniLine = document.getElementById("ani-line")

if(aniLine) {
  let lineHeight = aniLine.offsetHeight
  console.log(lineHeight)
  gsap.set(aniLine, {height: 0})
  
  let tl = gsap.timeline({
    scrollTrigger: {
      target: "#home-banner"
    }
  })
  tl
  .to(aniLine, {height: lineHeight, delay: 1})
}

// hover zoom images
const hoverZooms = document.querySelectorAll(".hover-zoom")
const hoverZoomTriggers = document.querySelectorAll(".hover-zoom-trigger")

if(hoverZooms.length && hoverZoomTriggers.length) {
  for (let i = 0; i < hoverZoomTriggers.length; i++) {
    const el = hoverZoomTriggers[i];
    el.addEventListener("mouseover", () => {
      hoverZooms[i].classList.add("zoomed")
    })
    el.addEventListener("mouseout", () => {
      hoverZooms[i].classList.remove("zoomed")
    })
    
  }
}



// Transition on anchor links
const links = document.querySelectorAll("a")
const sections = document.querySelectorAll("section")

for (let i = 0; i < links.length; i++) {
  const link = links[i];
  link.addEventListener("click", (e) => {
   
    if(link.href.split('#')[1]) {
    } else {
      if(e.target.id !== "contact-modal-btn" && e.target.id !== "nav-call-btn"){
        // console.log(e.target.id)
       e.preventDefault();
       let travelTo = link.getAttribute("href");
 
       const transitionTimeline = gsap.timeline({
       })
       transitionTimeline
       .to(sections, {opacity:0})
       .to(header, {opacity:0}, "<")
       .to(cursor, {opacity:0}, "<")
       .to(cursorinner, {opacity:0}, "<")
 
 
       setTimeout(function() {  
         if(travelTo) {
           window.location.href = travelTo;
         } else {
           window.location.href = window.location.origin
         }
       }, 300);
     }
     
   }
  })
}

// Main fade scroll trigger animation for Supergiant
function animateFrom(elem) {
  var x = 0,
  y = 20,
  delay = .5
if(elem.classList.contains("gs_reveal_fromLeft")) { 
x = -20,
y = 0,
delay = .5
} else if(elem.classList.contains("gs_reveal_fromRight")) {
x = 20,
y = 0,
delay = .5
}else if(elem.classList.contains("gs_reveal_projectSub")) {
  delay = .8
}else if(elem.classList.contains("gs_reveal_projectSubTwo")) {
  delay = 1.6
}else if(elem.classList.contains("gs_reveal_stagger_1")) {
  delay = 1
}else if(elem.classList.contains("gs_reveal_stagger_2")) {
  delay = 1.5
}else if(elem.classList.contains("gs_reveal_stagger_3")) {
  delay = 2
}else if(elem.classList.contains("gs_reveal_stagger_4")) {
  x = 0,
  y = 20,
  delay = 1.1
}else if(elem.classList.contains("gs_reveal_stagger_5")) {
  x = 0,
  y = 0,
  delay = 1.3
}else if(elem.classList.contains("gs_reveal_stagger_6")) {
  x = 0,
  y = 0,
  delay = 1.5
}else if(elem.classList.contains("gs_reveal_stagger_7")) {
  x = 0,
  y = 0,
  delay = 1.7
}else if(elem.classList.contains("gs_reveal_staggerOneBanner")) {
  delay = .5
  x = 0,
  y = direction * 50
  
}
else if(elem.classList.contains("gs_reveal_staggerTwoBanner")) {
  delay = .8
  x = 0,
  y = direction * 50
}else if(elem.classList.contains("gs_reveal_staggerThreeBanner")) {
  delay = 1.1
  x = 0,
  y = direction * 50
}

gsap.fromTo(elem, {x: x, y: y, opacity: 0}, {
duration: 1.5, 
x: 0,
y: 0, 
delay: delay,
opacity: 1, 
ease: "expo", 
overwrite: "auto"
});
}

var reveals = gsap.utils.toArray(".gs_reveal");
for(var i = 0; i < reveals.length; i++) {
(function () {
var elem = reveals[i];
//   hide(elem); // assure that the element is hidden when scrolled into view

  ScrollTrigger.create({
    trigger: elem,
    
    once: true,
    onEnter: function() { animateFrom(elem) }, 
    
  });
})();
}  


let footerWrapper = document.getElementById("footer-wrapper")
let footerPinWrapper = document.getElementById("footer-pin-wrapper")
let footerOverscroll = document.getElementById("footer-overscroll-wrapper")


// const addOverscroll = () => {
//   if(footerOverscroll) {
//     footerOverscroll.classList.remove("overflow-hidden")
//     footerOverscroll.classList.add("overflow-y-scroll")
    
//   } else {
//     console.log("no overscroll wrapper")
//   }
// }

// const removeOverscroll = () => {
//   if(footerOverscroll) {
//     footerOverscroll.classList.add("overflow-hidden")
//     footerOverscroll.classList.remove("overflow-y-scroll")
    
//   } else {
//     console.log("no overscroll wrapper")
//   }
// }

// ScrollTrigger.create({
//   trigger: footerWrapper,
//   start: "top top",
//   pin: footerPinWrapper
//   // onEnter: addOverscroll, 
//   // onLeave: removeOverscroll,
//   // onEnterBack: addOverscroll, 
//   // onLeaveBack: removeOverscroll,
  
// });



// site overlay
const overlayTimeline = gsap.timeline({
  delay:1
})
overlayTimeline
.to("body", {opacity:1})
.to(header, {opacity:1}, "<")
.to(cursor, {opacity:1}, "<")
.to(cursorinner, {opacity:1}, "<")
